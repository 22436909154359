/* 
 * @Author: 曹俊杰 
 * @Date: 2020年10月20日
 * @Module: 选择王牌运营
 */
<template>
  <el-select v-model="id"
             :placeholder="placeholder"
             :clearable="clearable"
             @change="change"
             @clear="clear"
             :filterable="filterable"
             :loading="loading">
    <el-option v-for="item in list"
               :key="item.id"
               :value="item.id"
               :label="item.user_name"></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "请选择",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    defaultvalue: {
      type: Number,
      defaule: null,
    },
  },
  data () {
    return {
      id: null,
      list: [],
      loading: true,
    };
  },
  components: {},
  mounted () {
    this.id = this.defaultvalue;
    this._initState();
  },
  methods: {
    async _initState () {
      const { status, data } = await this.$api.superBrokerOptions({
        zn_token: this.zn_token
      });
      if (status === 200) {
        this.list = data;
      }
      this.loading = false;
    },
    change (val) {
      this.$emit("change", val);
    },
    clear () {
      this.$emit("change", null);
    },
  },
};
</script>
<style>
</style>