/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-07 14:45:52
 * @Module: 主播入会审批表单
 */
 <template>
  <div>
    <div class="alert-style">
      <el-alert title="通过审批后，在主播列表，邀请主播对公结算，主播同意后，才可参与平台结算。否则未对公收益不参与平台结算。"
                type="warning"
                show-icon></el-alert>
    </div>
    <el-card style="padding-right:160px"
             class="anchor-form-style">
      <el-form ref="form"
               :model="form"
               :rules="rules"
               label-width="80px"
               :size="'mini'"
               :label-position="'top'">
        <el-row :gutter="90">
          <el-col :span="12">
            <el-form-item label="主播来源："
                          prop="origin"
                          :error="errorMessage?errorMessage.origin:''">
              <el-select v-model="form.origin"
                         placeholder="请选择"
                         class="width100">
                <el-option v-for="item in [{
									value:0,
									label:'自招主播'
								},
								{
									value:1,
									label:'星探推荐'
								}]"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-if="star_data">
            <div class="star-data-style"
                 @click="starMode = true">
              <el-avatar :src="star_data.avatar"></el-avatar>
              <div class="star-data-right">
                <div>{{star_data.nick_name}}</div>
                <div style="margin-top:8px;">手机号：{{star_phone}}</div>
              </div>
            </div>
          </el-col>

          <el-col :span="12"
                  v-if="star_data">
            <el-form-item label="王牌运营："
                          prop="star_broker_id"
                          :error="errorMessage?errorMessage.star_broker_id:''">
              <superBroker @change="id=>form.star_broker_id = id"
                           placeholder="请选择王牌运营"
                           class="width100"></superBroker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="身份证号码："
                          prop="id_number"
                          :error="errorMessage?errorMessage.phone:''">
              <el-input v-model="form.id_number"
                        placeholder="身份证号码" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公会："
                          prop="mcn_id"
                          :error="errorMessage?errorMessage.mcn_id:''">
              <mcn class="width100"
                   :filterable="false"
                   @change="id=>form.mcn_id=id"
                   placeholder="请选择公会" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码："
                          prop="phone"
                          :error="errorMessage?errorMessage.phone:''">
              <el-input v-model="form.phone"
                        placeholder="主播在营业厅实名过的手机号码">
                <el-tooltip effect="dark"
                            placement="top-start"
                            slot="suffix"
                            content="手机号的实名信息，需要与主播探探号实名信息相符。如因特殊愿意无法提供，请联系MCNopen运营解决。">
                  <div style="cursor: pointer;line-height:40px;">
                    <span class="icon iconfont icon-wenhao"
                          style="color:#60C3F7"></span>
                  </div>
                </el-tooltip>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主播分成比："
                          prop="separate_ratio"
                          :error="errorMessage?errorMessage.separate_ratio:''">
              <pushMoney class="width100"
                         :filterable="false"
                         @change="id=>form.separate_ratio=id"
                         placeholder="请选择主播分成比：" />
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-role="['administrator']">
            <el-form-item label="经纪人："
                          prop="broker_id"
                          :error="errorMessage?errorMessage.broker_id:''">
              <brokerInviter @change="id=>form.broker_id = id"
                             placeholder="请选择经纪人"
                             class="width100"></brokerInviter>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-role="['administrator']">
            <el-form-item label="是否验证手机号："
                          prop="need_validate_phone"
                          :error="errorMessage?errorMessage.need_validate_phone:''">
              <el-select v-model="form.need_validate_phone"
                         placeholder="请选择"
                         class="width100">
                <el-option v-for="item in [{
									value:0,
									label:'否'
								},
								{
									value:1,
									label:'是'
								}]"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-button type="primary"
                     @click="submit">确定</el-button>
        </el-row>
      </el-form>
    </el-card>
    <customModal :visible="enrollmentContent!==null"
                 @handleOk="enrollmentHandle"
                 title="注意事项">
      <div style="line-height:30px;padding: 12px 20px 100px 20px;"
           v-html="zhuyishixiang"></div>
    </customModal>
    <customModal :visible="starMode"
                 showCancelBtn
                 @handleCancel="()=>this.form.origin=0"
                 @handleOk="()=>{_req_star_broker()}"
                 title="搜索星探">
      <div style="padding:0 34px;display:flex;align-items: center;">
        <div style="width:20%;">搜索星探：</div>
        <el-input v-model="star_phone"
                  placeholder="请输入星探手机号"></el-input>
      </div>
    </customModal>
  </div>
</template>
 <script>
import mcn from "components/formComponent/mcn";
import pushMoney from "components/formComponent/pushMoney";
import brokerInviter from "components/formComponent/brokerInviter";
import superBroker from "components/formComponent/superBroker";
import zhuyishixiang from "./zhuyishixiang";
export default {
  data () {
    return {
      form: {
        anchor_id: "", // 主播id
        id_number: "", // 身份证号码
        mcn_id: "", //公会
        phone: "", //电话
        separate_ratio: "", //分成比
        broker_id: "", //经纪人
        need_validate_phone: 1, //是否验证手机号
        origin: 0,
        star_broker_id: ""  //王牌运营
      },
      rules: {
        id_number: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        broker_id: [
          { required: true, message: "请选择经纪人", trigger: "blur" },
        ],
        mcn_id: [{ required: true, message: "请选择公会", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
        ],
        separate_ratio: [
          { required: true, message: "请选择分成比", trigger: "blur" },
        ],
      },
      errorMessage: null,
      reqLoading: false,
      zhuyishixiang,
      enrollmentContent: true,
      starMode: false,
      star_phone: '',//星探手机号
      star_data: null
    };
  },
  created () {
    this.$store.commit("layouts/setPathList", [
      { name: "主播入会审批", path: "/anchor/enrollmentList" },
      { name: "通过审批" },
    ]);
    this.form.anchor_id = this.$route.query.anchor_id;
    this.$store.commit("layouts/changeBreadcrumb", true);
    if (!this.role(["administrator"])) {
      this.form.broker_id = this.$store.state.user.userInfo.user_id;
    }
  },
  beforeDestroy () {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },
  components: {
    mcn,
    pushMoney,
    brokerInviter,
    superBroker
  },
  mounted () { },
  methods: {
    enrollmentHandle () {
      this.enrollmentContent = null
    },
    submit () {
      this.errorMessage = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this._reqAnchorCreate();
        } else {
          return false;
        }
      });
    },
    // 请求通过审批
    async _reqAnchorCreate () {
      this.reqLoading = true;
      const { form } = this;
      const { status_code, message } = await this.$api.inviteAccept({
        ...form,
        star_id: this.star_data ? this.star_data.id : null
      });
      if (status_code === 200) {
        this.$router.go(-1);
        this.$message({
          message,
          type: "success",
        });
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
      this.reqLoading = false;
    },
    // 获取星探资料
    async _req_star_broker () {
      console.log(this.zn_token)
      const { status, info, data } = await this.$api.getStarBroker({
        phone: this.star_phone,
        zn_token: this.zn_token
      });
      if (status === 200) {
        this.starMode = false;
        this.star_data = data
      } else if (status === 422) {
        this.errorMessage = info;
      } else {
        this.$message({
          message: info,
          type: "warning",
        });
      }
      this.reqLoading = false;
    },
  },
  watch: {
    "form.origin": {
      handler (newName) {
        if (newName === 1) {
          this.starMode = true
        } else {
          this.starMode = false
          this.star_data = null
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
 <style lang="less" scoped>
.width100 {
  width: 100%;
}
.anchor-form-style /deep/ .el-input__inner {
  height: 40px;
}
.anchor-form-style /deep/ .el-form-item {
  margin-bottom: 40px;
}
.alert-style {
  margin-bottom: 9px;
  & /deep/ .el-alert--warning {
    color: #60c3f7;
    background: rgba(96, 195, 247, 0.1);
    font-size: 13px;
  }
}
.star-data-style {
  margin: 29.5px 0px;
  width: 100%;
  height: 59px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  padding: 0 8px;
  .star-data-right {
    font-size: 12px;
    color: #333;
    margin-left: 8px;
  }
}
</style>